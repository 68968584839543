import React from "react";
import { urlsApi } from "../../Services/Constantes";
import axios from "axios";
import { registerRequest } from '../../actions'
import { loginRequest } from '../../actions'
import { connect } from "react-redux";
import "../modal/modalLogin.css";
import { withRouter } from "react-router-dom";

class ModalLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interruptor: false,
      error: false,
      loading: false,
      form: {
        nameR: "",
        lastnameR: "",
        password: "",
        passwordR: "",
        emailR: "",
        email: "",
        emialO: "",
        men: ""
      }
    };
  }

  handleSubmitLog = async (e) => {
    this.setState({loading: true});
    e.preventDefault();
    const data = {
      email: this.state.form.email,
      password: this.state.form.password
    };
    var dataToState = {
      userName: "",
      userID: "",
      firstReg: ""
    };
    await axios({
      url: `${urlsApi.login}`,
      method: "POST",
      data: data,
      headers: {
        Authorization: "Bearer " + urlsApi.token
      }
    })
      .then((response) => {
        this.setState({
          form: { men: response.data.mensaje},
          loading: false,
        });
        if (response.data[0].id_persona) {
          dataToState = {
            userName: this.state.form.name,
            userID: response.data[0].id,
            firstReg: false
          };
          // console.log(response.data[0]);
          localStorage.setItem("userID", response.data[0].id);
          localStorage.setItem("user", response.data[0]);
          localStorage.setItem("email", this.state.form.email);
          localStorage.setItem("firstReg", false);
          console.log("Voy a redireccionar");
          this.props.loginRequest(dataToState);
          this.props.history.push("/my-bmc");
        } else {
          alert("No se encontro el usuario ó la contraseña no es correcta");
        }
      })
      .catch((error) => {
        this.setState({
          error: true,
        });
        return error;
      });
  };
  handleSubmitReg = async (e) => {
    e.preventDefault();
    this.setState({loading: true});
    const data = {
      correo: this.state.form.emailR,
      contrasenia: this.state.form.passwordR,
      nickname: this.state.form.nameR
      //apellido: 'apellido',
    };
    var dataToState = {
      userName: "",
      userID: "",
      firstReg: ""
    };
    await axios({
      url: `${urlsApi.registro}`,
      method: "POST",
      data: data,
      headers: {
        Authorization: "Bearer " + urlsApi.token
      }
    })
      .then((response) => {
        this.setState({loading: false});
        if (response.data.success === true) {
          localStorage.setItem("userID", response.data.id_usuario);
          localStorage.setItem("user", response.data);
          localStorage.setItem("userName", this.state.form.nameR);
          localStorage.setItem("email", this.state.form.emailR);
          dataToState = {
            userName: response.data.nickname,
            userID: response.data.id_usuario,
            userEmail: this.state.form.emailR,
            firstReg: true,
        }
          localStorage.setItem("firstReg", true);
          this.props.registerRequest(dataToState);
          this.props.history.push("/confirm");
        } else {
          this.setState({
            form: {
              men: response.data
            }
          });
        }
      })
      .catch((error) => {
        this.setState({ error: error });
        console.log(error);
        return error;
      });
  };
  handleForget = async (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    const data = {
      correo: this.state.form.emailO
    };
    await axios({
      url: `${urlsApi.recuperarcontrasenia}`,
      method: "POST",
      data: data,
      headers: {
        Authorization: "Bearer " + urlsApi.token
      }
    })
      .then((response) => {
        this.setState({ loading: false });
        if (response.data.success === true) {
          this.setState({
            form: {
              men: response.data.message
            }
          });
        } else {
          this.setState({
            form: {
              men: response.data.message
            }
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false, error: error });
        console.log(error);
        return error;
      });
  };
  handleChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  // const [interruptor, setInterruptor] = useState(true);
  render() {
    return (
      <div className="Modal_overlay">
   
        <div className="menumodal">
          <div
            onClick={() => this.setState({ interruptor: true })}
            className={`colmenumodal1 ${
              !this.state.interruptor && "btnmodalactive"
            }`}
          >
            <p>Ingresar</p>
          </div>
          <div
            onClick={() => this.setState({ interruptor: false })}
            className={`colmenumodal1 ${
              this.state.interruptor && "btnmodalactive"
            }`}
          >
            <p>Registrarte</p>
          </div>
        </div>
        {this.state.interruptor && (
          <div className="ModalContainer">
            <p className="headmodal">
              {" "}
              <span className="textbold"> Iniciar sesion </span>y avanza hacia
              la definición de tu
              <span className="textbold"> modelo de negocio.</span>
            </p>
            <p className="subhead">
              {" "}
              Sí ya tienes cuenta en wortev.com puedes usar tus mismos datos
              para acceder
            </p>

            <button className="closebtn" onClick={this.props.click}>
              x
            </button>
            <div className="emailbox">
              <div className="inputemailwv">
                <div className="correowv" for="emailAddress">
                  Correo:
                </div>
                <input
                  className="emailwvbox"
                  type="email"
                  size="32"
                  minlength="3"
                  maxlength="64"
                  value={this.state.form.email}
                  onChange={this.handleChange}
                  name="email"
                />
                <div className="passwordwv" for="password">
                  Contraseña:
                </div>
                <input
                  className="passbox"
                  type="password"
                  size="32"
                  minlength="3"
                  maxlength="64"
                  name="password"
                  value={this.state.form.password}
                  onChange={this.handleChange}
                />
                <div className="buttonlogin-forgetpass">
                  <button
                    className="btningresarmodal"
                    type="submit"
                    onClick={this.handleSubmitLog}
                  >{this.state.loading ? "Cargando..." : "Ingresar"}
                  </button>
                  {this.state.error && <p className="message_error" >{this.state.form.men}</p>}
                  <p type="button" className="forgetpassword">
                    ¿Contraseña olvidada?{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {!this.state.interruptor && (
          <div className="ModalSigninContainer">
            <button className="closebtn" onClick={this.props.click}>
              x
            </button>
            <p className="headmodal">
              {" "}
              <span className="textbold"> Crea una cuenta </span>gratuita para
              iniciar la
              <span className="textbold"> transformación de tu negocio.</span>
            </p>
            <div className="emailbox">
              <div className="inputemailwv">
                <div className="correowv" for="emailAddress">
                  Nombre de Usuario:
                </div>
                <input
                  className="emailwvbox"
                  type="text"
                  size="32"
                  minlength="3"
                  maxlength="64"
                  name="nameR"
                  value={this.state.form.nameR}
                  onChange={this.handleChange}
                />
                <div className="" for="emailAddress">
                  Correo:
                </div>
                <input
                  className="emailwvbox"
                  type="email"
                  size="32"
                  minlength="3"
                  maxlength="64"
                  name="emailR"
                  value={this.state.form.emailR}
                  onChange={this.handleChange}
                />
                <div className="passwordwv" for="password">
                  Contraseña:
                </div>
                <input
                  className="passbox"
                  type="password"
                  size="32"
                  minlength="3"
                  maxlength="64"
                  name="passwordR"
                  value={this.state.form.passwordR}
                  onChange={this.handleChange}
                />
                <div className="buttonsignin">
                  <button
                    className="btningresarmodal"
                    type="submit"
                    onClick={this.handleSubmitReg}
                  >{this.state.loading ? "Cargando..." : "Registrarse"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  loginRequest,
  registerRequest,
};

export default withRouter(connect(null, mapDispatchToProps)(ModalLogin));
