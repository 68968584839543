import React from "react";

class Slider extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div class="mastergallery">
          <div class="acc-wraper">
            <div class="accordion">
              <div>
                <a target="_blank">
                  <iframe
                    width="560"
                    height="315"
                    src="https://player.vimeo.com/video/745077695?h=b9b235397a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>

                </a>
                
                <div class="acc-texto">
                  <h4 class="gallery-titulo">
                    Con instructores especialistas <span></span>
                  </h4>
                  <p>
                    Descubre como organizar tu proyectos en 9 pasos <br /> 60mn
                  </p>
                </div>
              </div>
            
     
            </div>
          </div>
        </div>

       
      </React.Fragment>
    );
  }
}

export default Slider;

