import React from "react";
import ImageS2v1 from "../../images/image 62.png";

class Section2v1 extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="ebookbmccontain">
          <div className="bmcebook">
            <div className="ebooktextcontain">
              <p className="ebooktitle">Bussines Model Canvas </p>
              <p className="ebooksubtitle">
                Estás a un paso de alinear tu modelo de negocio con la
                herramienta visual más efectiva para emprender.{" "}
              </p>
              <p className="ebooktext">
                Con nuestro Business Model Canvas le darás estructura a tu
                empresa en nueve pasos. Es tan sencilla de usar que al final
                podrás explicarle de forma clara tu modelo de negocio a
                cualquier persona.{" "}
              </p>
            </div>
            <div className="ebookimageandbotton">
              <img
                className="imagens2v1"
                src={ImageS2v1}
                alt="image section2v1 "
              />
              <a
                className="btnebook"
                href="https://wortev.com/ebook-business-model-canvas/"
              >
                <button className="btnebook">Descarga e-book</button>
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Section2v1;
