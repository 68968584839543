import React from "react";

class Mail extends React.Component{
    render(){
        return(
            <React.Fragment>
                <div id="mlb2-5696795" className="ml-form-embedContainer ml-subscribe-form ml-subscribe-form-5696795">
                    <div className="ml-form-align-center">
                        <div className="ml-form-embedWrapper embedForm">
                            <div className="ml-form-embedBody ml-form-embedBodyDefault row-form">
                                <form  action="https://static.mailerlite.com/webforms/submit/f5h3d8" data-code="f5h3d8" method="post" target="_blank">
                                    <div className="ml-form-formConten">
                                        <div className="ml-form-fieldRow ml-last-item">
                                            <div className="ml-field-group ml-field-email ml-validate-email ml-validate-required">
                                                <input aria-label="email" aria-required="true" type="email" class="form-control" data-inputmask="" name="fields[email]" placeholder="Correo" autocomplete="email"></input>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="hidden" name="ml-submit" value="1"></input>
                                    <div className="ml-form-embedSubmit">
                                        <button type="submit" class="primary">Quiero recibir noticias</button> 
                                        <button disabled="disabled"  type="button" className="loading"  style={{display:"none"}}><div className="ml-form-embedSubmitLoad"></div> <span className="sr-only">Loading...</span></button>
                                    </div>
                                    <input type="hidden" name="anticsrf" value="true"></input>
                                </form>
                            </div>
                            <div className="ml-form-successBody row-success" style={{display:"none"}}>
                                <div className="ml-form-successContent">
                                    <h4>Gracias!</h4>
                                    <p>Subscribción exitosa</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </React.Fragment>
        )
    }
}
export default Mail;